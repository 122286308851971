@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* This fixes invisible text when using html2canvas */
.text-elem {
    position: relative;
}

/* This class is used to hide things that should not be visible in printing (saved to pdf or png) */
.hiddenPrint {}

/* Show a disabled circle when download buttons are disabled */
.download_button:disabled:hover {
    cursor:not-allowed
 }